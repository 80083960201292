<template>
  <section>
    <div class="content-header">
      <h2>Designing your life</h2>
    </div>
    <div class="content-wrapper">
      <p>Applying Design Thinking principles to career development, Stanford professors Bill Burnett and Dave Evans encourage students to 1) Get Curious; 2) Talk to people; 3) Try stuff; and 4) Tell your story. They do this through an in-depth and active design thinking process. </p>
      <p>There are a number of ways you can learn about and share Evans’ and Burnett’s approach to career resilience:</p>
      <ul>
        <li>Their book, <a
            href="https://designingyour.life/the-book/"
            target="_blank"
          >Designing Your Life</a> as well as their two follow-up books, <a
            href="https://designingyour.life/designing-your-work-life-book/"
            target="_blank"
          >Designing Your Work Life</a> and <a
            href="https://designingyour.life/designing-your-new-work-life/"
            target="_blank"
          >Designing Your New Work Life</a></li>
        <li>The <a
            href="https://designingyour.life/"
            target="_blank"
          >Designing Your Life website</a></li>
        <li>The <a
            href="https://podcasts.apple.com/us/podcast/designing-your-work-life-bill-burnett/id1231685425?i=1000464165949"
            target="_blank"
          >Creative Confidence Podcast: Designing Your Work Life</a> with Bill Burnett</li>
        <li>Bill’s Ted Talk: <a
            href="https://www.youtube.com/watch?v=SemHh0n19LA"
            target="_blank"
          >5 steps to designing the life you want</a>
        </li>
      </ul>
      <p>Which of these helpful resources will you share with students?</p>
    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '07',
  components: {
    // SidebarMenu,
    // Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
